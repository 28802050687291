export const encodeTipoEsito = {
    "PLACEMENT TEST": 0,
    "TEST FINALE": 1,
    "CERTIFICAZIONE": 2,
    "TENTATIVI SUCCESSIVI": 3,
    "TEST DI INGRESSO": 4,
    "LIVELLO TRIENNALE": 5,
}

export const encodeLivello = {
    "A1": 0,
    "A2": 1,
    "B1": 2,
    "B2": 3,
    "C1": 4,
    "C2": 5,
    "ASSENTE": 6,
    "ANNULLATO": 7,
    "NON SUPERATO": 8,
    "CONVALIDA": 9,
    "DECADUTO" : 10,
    "NON AMMESSO" : 11
}

export const decodeTipoEsito = {
    0: "PLACEMENT TEST",
    1: "TEST FINALE",
    2: "CERTIFICAZIONE",
    3: "TENTATIVI SUCCESSIVI",
    4: "TEST DI INGRESSO",
    5: "LIVELLO TRIENNALE"
}

export const decodeLivello = {
    0: "A1",
    1: "A2",
    2: "B1",
    3: "B2",
    4: "C1",
    5: "C2",
    6: "ASSENTE",
    7: "ANNULLATO",
    8: "NON SUPERATO",
    9: "CONVALIDA",
    10: "DECADUTO",
    11: "NON AMMESSO"
}


export const mapIdStudente = {
    "RINUNCIA": 0,
    "TRASFERIMENTO": 1,
    "FINE CARRIERA": 2,
    "ATTIVO": 3,
    "NON MATRICOLE": 5,
    "NON SLAM": 6,
    "SOSPENSIONE" : 7,
    "LAUREATO": 8
}

export const mapStatoStudente = {
    0: "RINUNCIA",
    1: "TRASFERIMENTO",
    2: "FINE CARRIERA",
    3: "ATTIVO",
    5: "NON MATRICOLE",
    6: "NON SLAM",
    7: "SOSPENSIONE",
    8: "LAUREATO"
}