import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faEnvelope, faFileCsv, faSync } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";

class SectionImportaCsv extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }
    }

    render() {
        return (
            <Navbar className='d-flex flex-row-reverse mb-3' expand="lg">
                <Nav style={{ color: 'green' }} className={this.state.isMobile ? 'mb-2' : ''}>
                    <Link to={{ pathname: '/UpdateCV', state: { filters: this.props.filters, isActiveSearch: this.props.isActiveSearch } }}><Button title={'Clicca per aggiornare i CV'} style={{ backgroundColor: '#FE9900', border: 'black' }}><span className='mr-2'>Aggiorna CV</span><FontAwesomeIcon icon={faSync} /></Button></Link>
                </Nav>
                <Nav style={{ color: 'green' }} className={this.state.isMobile ? 'mb-2' : ''}>
                    <Link to={{ pathname: '/importaEsiti', state: { filters: this.props.filters, isActiveSearch: this.props.isActiveSearch } }}><Button title={'Clicca per caricare gli esiti'} variant={'success'} className='mr-2'><span className='mr-2'>Importa esiti</span><FontAwesomeIcon icon={faFileCsv} /></Button></Link>
                </Nav>
                <Nav style={{ color: 'green' }}>
                    <Link to={{ pathname: '/importaMatricole', state: { filters: this.props.filters, isActiveSearch: this.props.isActiveSearch } }}><Button title={'Clicca per caricare le matricole'} variant={'primary'} className='mr-2'><span className='mr-2'>Importa matricole</span><FontAwesomeIcon icon={faAddressCard} /></Button></Link>
                </Nav>
                <Nav style={{ color: 'green' }}>
                    <Link to={{ pathname: '/checkConvocazioni', state: { filters: this.props.filters, isActiveSearch: this.props.isActiveSearch } }}><Button title={'Clicca per verificare le mail da inviare'} variant={'dark'} className='mr-2'><span className='mr-2'>Check invio e-mail PT</span><FontAwesomeIcon icon={faEnvelope} /></Button></Link>
                </Nav>
                {/* <Nav> */}
                {/* <Button className='mr-2' onClick={() => window.location.href = `${process.env.REACT_APP_CAS}/login?service=${process.env.REACT_APP_API_URL}:3000`}>Login</Button> */}
                {/* <Button className='mr-2' onClick={() => window.open('https://sso.staging.unimi.it:6443/login?service=http://192.168.0.134:3000', '_blank')}>Login</Button> */}
                {/* </Nav> */}
            </Navbar>)
    }
}

export default SectionImportaCsv;