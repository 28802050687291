import React from 'react';
import { Button, Breadcrumb, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFileCsv, faCheck } from '@fortawesome/free-solid-svg-icons'
import papa from 'papaparse';
import axios from 'axios';

import _, { map } from 'underscore';

class UpdateCV extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            csvStatus: 'load',
            csvData: null,
            csvJson: null
        }


    }

    updateCsvData = (updStat, csvJson) => {
        this.setState({ csvStatus: updStat, csvJson: csvJson })
    }

    handleFileSelected = (e) => {
        this.setState({ csvData: e.target.files[0] })
    }

    // reloadCsv = () => {
    //     this.updateCsvData('load', null);
    //     this.setState({ csvData: null })
    // }

    generateJsonFromCsv = () => {
        let self = this;
        if (this.state.csvData) {
            papa.parse(this.state.csvData, {
                encoding: "UTF-8",
                complete: function (results) {
                    self.updateCV(results.data);
                    self.updateCsvData('details', results.data);
                },
                skipEmptyLines: true
            });
        }

    }

    updateCV = (results) => {
        let self = this;
        //controllo se i CDL in oggetto sono solo D64
        if (results) {
            let studentiD64 = [];
            results.forEach((item, idx) => {
                if (idx !== 0 && item[0].trim().toUpperCase() === 'D64') {
                    studentiD64.push({ matricola: item[4], cv: item[10] })
                }
            })

            let objStudenti = _.groupBy(studentiD64, 'cv');

            axios.post(`${process.env.REACT_APP_API_URL}/updateCV`, objStudenti, this.props.authParam).then((resp) => {
                document.getElementById('files').value = '';
                this.setState({ csvData: null, alertVisible: true })

                setTimeout(function () {
                    self.setState({ alertVisible: false })
                }, 1000)
            })
        }
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    render() {
        return (
            <div>
                {/* <div><Button variant="primary" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Torna alla Home </Button></div> */}
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Aggiorna CV
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className='mt-3'>
                    <h3>AGGIORNA CV</h3>
                    <p>In questa sezione è possibile aggiornare il CV degli studenti, tramite il caricamento di un file csv</p>
                </div>
                <div>
                    <div>
                        <input className='form-control-file' onChange={this.handleFileSelected} type='file' id='files' accept='.csv' required></input></div>
                    {this.state.csvData ?
                        <Button onClick={this.generateJsonFromCsv} className='mt-3' variant='success'>Elabora <FontAwesomeIcon icon={faFileCsv} /></Button> : null}
                    {this.state.alertVisible ? <Alert variant={'success'} className='mt-2'>
                        Esiti caricati correttamente <FontAwesomeIcon icon={faCheck} />
                    </Alert> : null}
                </div>
            </div >)
    }
}

export default UpdateCV;