import React, { Fragment } from 'react';
import { Button, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPencilAlt, faPlusSquare, faTrash, faHome } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import ModalAdminPage from '../sections/ModalAdminPage';
import Loader from '../components/Loader';

class AdminPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            users: null,
            showModalAdminPage: false,
            dataModal: {
                USER: '',
                ROLE: ''
            },
            initialDataModal: {
                USER: '',
                ROLE: ''
            },
            adminPageAction: '',
            reloadPage: false
        }

        this.columns = [
            {
                name: 'UTENTE',
                selector: 'USER',
                sortable: true,
                cell: row => <div>{row.USER}</div>,
            },
            {
                name: 'RUOLO',
                selector: 'RUOLO',
                sortable: true,
                maxWidth: '0.5rem',
                cell: row => <div>{row.RUOLO}</div>,
            },
            {
                name: '',
                selector: 'AZIONI',
                sortable: true,
                right: true,
                cell: row => <div><Button onClick={() => this.openModalAdminPage(row, 'M')}><FontAwesomeIcon icon={faPencilAlt} className={'curosorHover'} size={'1x'} title='Modifica Utente' /></Button> <Button onClick={() => this.openModalAdminPage(row, 'D')} variant="danger"><FontAwesomeIcon  icon={faTrash} className={'curosorHover'} size={'1x'} title='Cancella Utente' /></Button></div>
            }
        ]

    }

    callgetUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getUsers`, this.props.authParam).then((resp) => {
            //tolgo l'utente corrente dalla lista
            let users = resp.data.filter(us => us.USER !== this.props.email)
            this.setState({ users: users })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    componentDidMount(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({isMobile: true})
        }

       this.callgetUsers();
    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    closeModalAdminPage = (isReloadPage) => {
        if (isReloadPage) this.reloadPage();
        this.setState({ showModalAdminPage: false, dataModal: JSON.parse(JSON.stringify(this.state.initialDataModal)) })
    }

    reloadPage = () => {
        this.callgetUsers();
        this.setState({ reloadPage: true });
        setTimeout(() => {
            this.setState({ reloadPage: false })
        }, 300)
    }

    openModalAdminPage = (row, action) => {
        let dataModal = this.state.dataModal;
        if(row){
            dataModal.USER = row.USER;
            dataModal.ROLE = row.RUOLO;
        }
        let adminPageAction = action;
        this.setState({ showModalAdminPage: true, dataModal: dataModal, adminPageAction: adminPageAction })
    }

    render() {
        return (
            <Fragment>
                {/* <Button variant="primary" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Indietro </Button> */}
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Admin page
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className='mt-3'>
                    <h3>PAGINA DI AMMINISTRAZIONE</h3>
                    <p>Gestisci qui gli utenti che possono accedere all'applicativo. I Ruoli gestiti sono: </p>
                    <br />
                    <p><b>ADMIN</b> - gestisce gli utenti e ha la possibilità di visualizzare e modificare i dati</p>
                    <p><b>W</b> - ha la possibilità di visualizzare e modificare i dati</p>
                    <p><b>R/O</b> - ha la sola possibilità di visualizzare i dati</p>
                </div>
                <Button onClick={() => this.openModalAdminPage(null, 'I')}>Aggiungi Utente <FontAwesomeIcon icon={faPlusSquare} /></Button>
                <div style={{paddingLeft: !this.state.isMobile ? '20%' : '0px', paddingRight: !this.state.isMobile ? '20%' : '0px'}}>
                   {this.state.users && !this.state.reloadPage ?  <DataTable
                        className={'tableCustomStyle'}
                        columns={this.columns}
                        data={this.state.users}
                        pagination={true}
                        paginationPerPage={5}
                        striped
                        highlightOnHover
                        responsive
                    /> : this.state.reloadPage ? <Loader /> : <div>Non sono presenti utenti, clicca sul bottone Aggiungi Utente per iniziare</div> }
                </div>
                {this.state.showModalAdminPage ? <ModalAdminPage dataModal={this.state.dataModal} adminPageAction={this.state.adminPageAction} authParam={this.props.authParam} setErrors={this.props.setErrors} showModalAdminPage={this.state.showModalAdminPage} openModalAdminPage={this.openModalAdminPage} closeModalAdminPage={this.closeModalAdminPage}/> : null}
            </Fragment>)
    }
}

export default AdminPage;